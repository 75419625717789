import 'material-design-icons-iconfont/dist/material-design-icons.css'

<template>
     
  
  <div class="pa-3">

      <!-- showing the snackBar Message -->
      <v-snackbar  v-model ="snackbar.display" :timeout ="snackbar.timeout" top color="primary">
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
         <v-btn color="pink" text v-bind="attrs" @click="snackbar.display = false" >
          Close
         </v-btn>
       </template>

      </v-snackbar>

      <!-- showing the uploading a Meter Reading dialog -->
      <v-dialog  v-model ="upLoadingDialog.show"  width="70%">
 
           <v-card loading rounded="lg" elevation="16" class="mb-2 mx-auto">

            <v-card-title primary-title class="justify-center">{{upLoadingDialog.text}}</v-card-title>
            
              <v-card-text v-if="upLoadingDialog.upLoadingImage">

                <v-divider class="mb-2"></v-divider>

                    <table>
                          <tbody>
     
                              <tr class="title">
                              <td>Percentage: </td>
                              <td>{{  uploadedPercent }}</td>
                              </tr>
    
                              <tr class="title">
                              <td>Total:</td>
                              <td>{{  uploadedTotal.toLocaleString() }}</td>
                              </tr>
   
                              <tr class="title">
                              <td>Loaded:</td>
                              <td>{{  uploadedLoaded.toLocaleString() }}</td>
                              </tr>
                          </tbody>

                    </table>
                        
              </v-card-text>

            </v-card>

      </v-dialog>
 
      <!-- showing the previous Meter Read Dialog -->
      <v-dialog  v-model = "previousMeterDialog"  width="400">
    
                <v-card>
              
                  <v-card-title>
                     Previous meter reading
                  </v-card-title>


                  <v-card-subtitle>
                      <p>
                         Account: {{ this.meterReading.customer_id }}
                      </p>
                  </v-card-subtitle>

               
                  <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field type='text'   disabled dense  v-model="previousReading.meter_id"        outlined label=  "Meter Identifier"  hint = "Meter Identifier"></v-text-field>
                          <v-text-field type='text'   disabled dense  v-model="previousReading.meter_metric"    outlined label=  "Meter Metric"      hint = "Meter Metric"></v-text-field>
                
                          <div class="d-flex"> 
                            <v-text-field class="mr-2" type='number' disabled dense  v-model="previousReading.meter_left"      outlined label=  "Meter Left"        hint = "Meter Left" ></v-text-field>
                            <v-text-field type='number' disabled dense               v-model="previousReading.meter_right"     outlined label=  "Meter Right"       hint = "Meter Right" ></v-text-field>
                          </div>

                          <v-text-field type='text'   disabled dense  :value= "computedReadDate"               outlined label = "Date"            hint = "Reading Date"></v-text-field>
                          <v-textarea   type='text'   disabled dense  v-model="previousReading.comments"        outlined label = "Comments:" readonly></v-textarea>
               
                        </v-col>
                      </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    
                    <v-btn  v-if="previousMeterImageAvailable" :loading="previousMeterImageLoading"  class="text-none"   size="large"      @click = "showLastestEngineerMeterImage">Show Photo</v-btn>

                    <v-btn    class="text-none"   size="large" color="primary" @click = "previousMeterDialog = false">Close</v-btn>
                  </v-card-actions>

              </v-card>

      </v-dialog>

      <!-- Display an image in a lightBox, used for the updated and previous image-->
      <vue-easy-lightbox 
            :visible   =  "lightBoxVisible" 
            :imgs      =  "lightBoxImage"
            @hide      =  "hideLightBoxImage"
      ></vue-easy-lightbox>
      
      <!-- File Input to select a Camera Image-->
      <label class="d-none">
          <input id="file-input"         v-on:change="imageChange"   type="file" accept="image/*" capture >
      </label>
      
      <!-- File Input to select a Gallery Image-->
      <label class="d-none">
           <input id="file-input_gallery" v-on:change="imageChangeGallery" type="file" accept="image/x-png,image/jpeg,image/gif">
      </label>
      
      <!-- View Header Title and previous Meter Button-->
      <v-row class="pa-2 mb-2 d-flex justify-space-between align-baseline">
                
            <div>Engineer Meter Reading</div>
            <div>   

              <v-btn  :loading = "loading" @click="showLastestEngineerMeter" class="mx-2" fab medium color="error">
                <v-icon dark>mdi-page-previous</v-icon>
              </v-btn>


            </div>

      </v-row>

      <!-- Meter Read Form-->
      <v-row >
            <v-expansion-panels class="expansion_panels">
          
              <!-- customerComponent -->
                <customer v-bind:customer = "customer" />
              <!-- customerComponent -->

              <!-- Current Meter Reading -->
                 <v-expansion-panel class="expansion_pane mb-1"> 
                  <v-expansion-panel-header>
                    <div>Meter Read</div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>


                    <v-row>
                        
                        <v-col cols="12" sm="6">

                          <v-form ref="meterReadForm" v-model="isFormValid">
                   
                             <v-text-field  density = "comfortable" :rules="meterIdRules"        required     type="text"    clearable hint="Meter Identifier" outlined  v-model="meterReading.meter_id"       label="Meter Identifier"></v-text-field>
                                                        
                              <div class="d-flex">
                               <v-text-field  class="mr-2" density = "comfortable" :rules="meterLeftRules"     required     type="number"   clearable hint="Meter Left"   outlined  v-model="meterReading.meter_left"    label="Meter Left"></v-text-field>
                               <v-text-field  density = "comfortable"              :rules="meterRightRules"    required     type="number"   clearable hint="Meter Right"  outlined  v-model="meterReading.meter_right"   label="Meter Right"></v-text-field>
                             </div>

                             <v-container fluid>
                             <p>Meter Metric: <strong>{{ meterReading.meter_metric }}</strong></p>
                             <v-radio-group inline v-model="meterReading.meter_metric">
                               <v-radio label="Meters" value="Meters"></v-radio>
                               <v-radio label="Feet"   value="Feet"></v-radio>
                            </v-radio-group>
                            </v-container>
                           
                            <v-textarea  dense outlined v-model="meterReading.comments" label="Comments:"></v-textarea>
               

                             
                             <v-card-actions>
                                <v-btn class="text-none" :disabled="!isFormValid" :loading = "loading" @click="uploadEngineerMeter" elevation="3" color="success" >Upload Meter Read</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn class="text-none" @click="resetMeterReadForm" elevation="3" color="error" >Reset Form</v-btn>
                             </v-card-actions>
                    
                             
                            </v-form>

                        </v-col>


                        <v-col  class="myborder pa-1" cols="12" sm="6">
                                           
                                           <v-card-actions>
                                                      <v-btn class="text-none" text color="primary" @click="takeImageCamera()">Camera
                                                      </v-btn>
        
                                                      <v-btn class="text-none" text color="primary" @click="takeImageGallery()">Gallery
                                                      </v-btn>
                                                                        
                                                      <v-spacer></v-spacer>
        
                                                      <v-btn class="text-none" text color="warning" @click="resetImage()">Reset
                                                      </v-btn>
        
                                           </v-card-actions>      
                                            
                                           <v-divider class="border-opacity-25"></v-divider>
                
                                           <div class="ma-2 myborder">
                                        
                                           <img    class   = "myimg" 
                                                   @click  = "showUpdatedMeterImage()"
                                                   :src    = "meterImage"
                                           />
                                        
                                          </div> 

                        </v-col>

                      
                    </v-row>


                  </v-expansion-panel-content>
                </v-expansion-panel>
              <!-- Current Meter Reading -->


              <!-- last one for space-->
                <v-expansion-panel class="expansion_panel mb-2"> 
                </v-expansion-panel>
              <!-- last one for space-->

            </v-expansion-panels>
     </v-row>

  </div>

</template>

<script>


import {store}   from '../../main.js'
import Localbase from 'localbase'

export default {

   name     : "meterRead",
   formName : 'meterRead',

    destroyed () {
       
           store.consoleLog( "meterRead form destroyed");

           this.customer                      = JSON.parse( localStorage.getItem( "equalResponseCustomer") );
           this.engineer                      = JSON.parse( localStorage.getItem( "equalResponseEngineer") );
           this.meterReading.engineer_id      = this.engineer['ENGINEER_ID'];
           this.meterReading.customer_id      = this.customer['ACCOUNT'];
           this.meterReading.meter_id         = this.customer['USER5'];

           this.createSnapShot();
    },
      
    created    (){ 
 
          store.consoleLog( "meterRead created");

          this.customer                         = JSON.parse( localStorage.getItem( "equalResponseCustomer") );
          this.engineer                         = JSON.parse( localStorage.getItem( "equalResponseEngineer") );
        
          this.meterReading.engineer_id        = this.engineer['ENGINEER_ID'];
          this.meterReading.customer_id        = this.customer['ACCOUNT'];
          this.meterReading.meter_id           = this.customer['USER5'];
          this.meterReading.meter_metric       = 'Meters';
          this.meterImage                      = this.$restServer.engineerMeterImageEmpty;
     
          this.restoreSnapShot();

    }, 


   methods   : {
    
            showLastestEngineerMeter(){

                const customerId = this.customer['ACCOUNT'];
                console.log(`getEngineerMeter( '${customerId}' )`);

                const resourceUrl  =  this.$restServer.engineerMeter;
                let   url          =  resourceUrl + "/" + customerId;

                this.loading = true;

                this.$http.get( url )
                .then(  (response) => {
                  
                  const meter       = response.data;
                  if (meter.status === 'success') {
                    
                    this.previousReading.engineer_id    = this.engineer['ENGINEER_ID'];
                    this.previousReading.customer_id    = this.customer['ACCOUNT'];
                    this.previousReading.meter_id       = this.customer['USER5'];

                    this.previousReading.meter_left     = meter.meter_left;
                    this.previousReading.meter_right    = meter.meter_right;
                    this.previousReading.created_at     = meter.created_at;
                    this.previousReading.meter_metric   = meter.meter_metric;
                    this.previousReading.comments       = meter.comments;
                    this.previousMeterImageAvailable   = (meter.meter_image_path) ? true : false;
                    
                    this.loading                       = false;
                    this.previousMeterDialog           = true;
         
                  } else if ( meter.status === 'error'){
               
                      this.snackbar.text                 = meter.message;
                      this.snackbar.display              = true;
                      this.loading                       = false;
                  }  
                      
                })
                .catch( (e) => {

                    this.snackbar.text    = e.response.data.exception;
                    this.snackbar.display = true;
                    this.loading          = false;
                });

            },

            showLastestEngineerMeterImage() {

                const customerId = this.customer['ACCOUNT'];
                console.log(`showLastestEngineerMeterImage( '${customerId}' )`);

                const resourceUrl  =  this.$restServer.engineerMeterImageLink;
                let   url          =  resourceUrl + "/" + customerId;
                this.previousMeterImageLoading      = true;

                this.$http.get( url )
                .then(  (response) => {
                    this.previousMeterImageLoading    = false;
                    this.lightBoxImage                = response.data;
                    this.lightBoxVisible              = true;
                  })
                  .catch( (e) => {
                    this.previousMeterImageLoading      = false;
                    console.log(e);
                  });



            },
            

            // upload engineers meter reading ..
            // step 1 to upload the meter read , if success
            // then upload the image if any
            async uploadEngineerMeter() {
      
              this.loading                        = true;
              this.upLoadingDialog.show           = true;
                                           
              try {
        
                    this.upLoadingDialog.text           = "Uploading Meter Read . !!";
                    let meterUploadResult               = await this.postEngineerMeter();
                     if ( meterUploadResult.data.status == 'error' ){
                         const errMessage = meterUploadResult.data.message; 
                         throw errMessage;
                    }
                              
                    this.upLoadingDialog.text           = "Uploading Meter Image.!!";
                    this.upLoadingDialog.upLoadingImage = true;
                    let meterImageUploadResult = await this.postEngineerMeterImage();
            
                    if ( meterImageUploadResult.data.status == 'error' ){
                         const errMessage = meterImageUploadResult.data.message; 
                         throw errMessage;
                    }

                    this.resetMeterReadForm();
                    
                      
              } catch (error) {

                  this.snackbar.display      =  true;
                  this.snackbar.text         =  error;
                  this.snackbar.timeout      = '3000';

              } finally {

                 this.loading                         = false;
                 this.upLoadingDialog.show            = false;
                 this.upLoadingDialog.upLoadingImage  = false;
                 this.upLoadingDialog.text            = "";

              }

            },
 
            generateEngineerMeterObj () {
                
              var meterReadObj = {
                                   id        : this.meterReading.customer_id, 
                                   leftMeter : this.meterReading.meter_left,
                                   rightMeter: this.meterReading.meter_right,
                };
                return meterReadObj;
            },
 
            generateEngineerMeterImageObj () {
                 
                 let   formData        =  new FormData();
                 const meterReadJson   = JSON.stringify(this.meterReading);
                 formData.append( 'meterImage',       this.meterImage);
                 formData.append( 'meterReading',     meterReadJson);
                 return formData;
              
            },
               
            async postEngineerMeter() {
               
              try {

                const enginerMeterURL          = this.$restServer.engineerMeter;
                const engineerMeterObject      = this.generateEngineerMeterObj();
                const result = await this.$http.post(enginerMeterURL, engineerMeterObject);
                return result;

              } catch (error) {
                return error;
              } 

            },
                     
            async postEngineerMeterImage() {

                try {

                  const engineerMeterImageObject  = this.generateEngineerMeterImageObj();
                  const engineerMeterImageURL     = this.$restServer.engineerMeterImage;
                  
                  return await this.$http.post(engineerMeterImageURL, engineerMeterImageObject, {
                                                
                                                headers               : { 'Content-Type': 'multipart/form-data'},
                                                onUploadProgress : ( progressEvent ) => {
                                                    this.uploadedPercent          = parseInt(Math.round( (progressEvent.loaded / progressEvent.total) * 100));
                                                    this.uploadedTotal            = progressEvent.total;
                                                    this.uploadedLoaded           = progressEvent.loaded;
                                                }   

                                              });

                } catch (error) {
                  return error;
                }
           
            },

            resetMeterReadForm () {

                this.meterReading.engineer_id        = this.engineer['ENGINEER_ID'];
                this.meterReading.customer_id        = this.customer['ACCOUNT'];
                this.meterReading.meter_id           = this.customer['USER5'];
                this.meterReading.meter_metric       = 'Meters';
                this.meterReading.meter_left         = null;
                this.meterReading.meter_right        = null;
                this.meterReading.comments           = null;
                this.meterImage                      = this.$restServer.engineerMeterImnageEmpty;
                this.createSnapShot();
           
            },
                        
            showUploadingDialog() {
                    this.uploadingDialog = true;
            },
    
            closeUploadingDialog() {
                          setTimeout( () => {
                              this.uploadingdDialog  = false;
                          }, 2000)
            },
            
            createSnapShot(){

                let snapShot             =  this.generateSnapShot();
                let db                   =  new Localbase( this.$dbName );

                db.collection( this.collection ).doc( this.meterReading.customer_id).set( snapShot )
                .then(response => { 
                   console.log( response)
                })
                .catch(error => {
                   console.log( error)
                })
            },

            restoreSnapShot(){

                let db = new Localbase(  this.$dbName );
                db.collection( this.collection).doc( this.meterReading.customer_id).get()
                .then( document => {
                  if ( document != null ) {
                         this.meterReading        = document['meterReading'];
                         this.meterImage          = document['meterImage'];
                         
                  }
                })
                .catch( error => {
                   console.log( error)
                });

            },

            generateSnapShot() {
                                let snapShot          =   { 
                                                            'meterImage'          : this.meterImage,
                                                            'meterReading'        : this.meterReading,
                                                          };
                                return snapShot;
            },

            takeImageGallery() {
                    document.getElementById('file-input_gallery').click();
            },

            takeImageCamera() {
                    document.getElementById('file-input').click();
            },

            resetImage() {

                    this.meterImage             = this.$restServer.engineerMeterImageEmpty;
                    this.meterReading.comments  = "";
                    this.createSnapShot();

            },

            async imageChangeGallery() {
                                          
                                          try {
                                         
                                              var file     = document.getElementById("file-input_gallery");
                                              if (file.files.length > 0 ) {
                                               
                                                  const photo                         = document.getElementById("file-input_gallery").files[0];
                                                  const dataUrl                       = await this.getDataUrl( photo  );
                                                  this.meterImage                     = dataUrl;
                                                  this.createSnapShot();
                                                  document.getElementById('file-input_gallery').value = null;
                                              }
                      
                                          }catch (error) {
                                              console.log(error); 
                                          }
                         
            },
                      
            async imageChange() {
                                          
                    try {
                   
                        var file     = document.getElementById("file-input");
                        if (file.files.length > 0 ) {
                         
                            const photo                         = file.files[0];
                            const dataUrl                       = await this.getDataUrl( photo );
                            this.meterImage                     = dataUrl;
                            this.createSnapShot();
                            document.getElementById('file-input').value = null;
                        }

                    }catch (error) {
                        console.log(error); 
                    }
   
            },
           
            getDataUrl( photo ) {

                        return new Promise(function(resolve, reject) {
                          
                          const reader = new FileReader();
                          reader.readAsDataURL(photo);
                          
                          reader.addEventListener('load',  function(e) {
                              const dataUrl = e.target.result; 
                              resolve( dataUrl );
                          });

                          reader.addEventListener('error', function(e) {
                             reject(e);
                          });

                        });
            },

            showUpdatedMeterImage() {
                    this.lightBoxImage          = this.meterImage;
                    this.lightBoxVisible        = true
            },
        
            hideLightBoxImage() {
                    this.lightBoxImage          =  null;
                    this.lightBoxVisible        =  false
            },
 
   },

    computed  :{
            computedReadDate() {
              
              if ( this.previousReading.created_at ) {

                   const date = new Date(this.previousReading.created_at);
                   return date.toLocaleString('en-GB');

              } else {
                 return "";
              }
         
            },

            computedPreviousReadingMeterImage() {
                return (this.meterReading.previous_image ) ? this.meterReading.previous_image : ""
            },
    },

   data() {

    return {
        
         snackbar           : { 
                                display : false,
                                text    : "",
                                timeout : 3000
         },

         loading                      : false,
         previousMeterImageLoading    : false,
         previousMeterImageAvailable  : false,
                 

         upLoadingDialog            : { 
                                         show            : false,
                                         upLoadingImage  : false,  
                                         text            : ""                                        
                                      },

         previousMeterDialog        : false,
                   
         isFormValid                : false,

         uploadedPercent            :  0, 
         uploadedTotal              :  0, 
         uploadedLoaded             :  0,

         meterIdRules: [
            v => !!v || 'Meter Identifier is required',
            v => (v && v.length <= 10) || 'Meter Identifier must be less than 10 characters',
         ],

         meterLeftRules: [
          v => !!v                         || 'Meter Left is required',
          v => ( v > 0 && v < 99999 )      || 'Meter Left must be between 0 and 99999' 
         ],

         meterRightRules: [
          v => !!v                         || 'Meter Right is required',
          v => ( v > 0 && v < 99999 )      || 'Meter Right must be between 0 and 99999' 
         ],


         meterMetricRules: [
            v => !!v || 'Meter Metric is required',
            v => (v && v.length <= 10) || 'Value must be less than 10 characters',

         ],


         lightBoxVisible           : false,
         lightBoxImage             : null,
         formName                  : "meterRead",
        
         customer          : {},
         salesman          : {},
         engineer          : {} ,

         collection        : "meterRead",
         
         previousReading   : {

                              engineer_id       :  "",
                              customer_id       :  "",
                              meter_id          :  "",

                              meter_metric       :  "",
                              meter_left         :  "",
                              meter_right        :  "",
                              comments           :  "",

                              created_at        :  "",


         },
         
         meterImage       :  this.$restServer.meterImageEmpty,
         
         meterReading     :  {
                              engineer_id       :  "",
                              customer_id       :  "",
                              meter_id          :  "",
                              meter_metric       :  "",
                                                      
                              meter_left         :  "",
                              meter_right        :  "",
                              comments           :  "",
                            },

        };

  }

};


</script>

<style scoped>

.myimg {

  object-fit : fill !important;
  width      : 100%  !important;
  height     : auto  !important;
  margin     : auto;
}

.myborder {
  border        : 1px solid black;
  border-radius : 4px !important;
}

.row {
  display: flex; /* equal height of the children */
}

.col {
  flex: 1; /* additionally, equal width */
}

.dcontents{
  display: contents !important;
}

h1,h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.dl_expansion_panel_content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.header{
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: bold;
}

input[type=text] {
  width: 100%;
  padding: 8px 20px;
  margin: 2px 0;
  box-sizing: border-box;
  border: 3px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

input[type=text]:focus {
  border: 3px solid #555;
}

div.v-text-field__details {
  display: none;
}

legend.v-label {
  font-size: 16px !important;
}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}
.fill img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}

.test-flex-container{
    min-height: 300px;
    margin: 0 auto;
    display: -webkit-flex; /* Safari */     
    display: flex; /* Standard syntax */
}

.test-flex-container .column{
    padding: 10px;
    -webkit-flex: 1; /* Safari */
    -ms-flex: 1; /* IE 10 */
    flex: 1; /* Standard syntax */
}

</style>
       
